import { Component, ElementRef, EventEmitter, HostListener, Inject, input, Input, Optional, output, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-base-dialog',
  templateUrl: './base-dialog.component.html',
  styleUrls: ['./base-dialog.component.scss'],
  standalone: true,
  imports: [NgClass, MatDialogTitle, MatIcon, MatDialogContent, NgTemplateOutlet, TranslocoModule],
})
export class BaseDialogComponent {
  @Input() public dialogTitle?: string;
  @Input() public infoLabels?: Array<string>;
  @Input() public hideClose?: boolean;
  @Input() public infoMenu?: boolean;
  public icon = input<string>();
  public iconClicked = output<void>();

  @Output() public readonly closeDialog = new EventEmitter<void>();

  @ViewChild('closeIcon', { static: false }) private closeIcon?: MatIcon;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) dialogData: { title: string; hideClose: boolean },
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) bottomSheetData: { title: string; hideClose: boolean },
    @Optional() public dialogRef: MatDialogRef<BaseDialogComponent>,
    @Optional() public bottomSheetRef: MatBottomSheetRef<BaseDialogComponent>,
    private elementRef: ElementRef
  ) {
    const data = dialogData || bottomSheetData;
    if (!data) {
      return;
    }
    if (data.title && !this.dialogTitle) {
      this.dialogTitle = data.title;
    }
    if (data.hideClose && !this.hideClose) {
      this.hideClose = data.hideClose;
    }
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    if (!this.infoMenu) {
      return;
    }

    if (this.closeIcon?._elementRef?.nativeElement.contains(event.target as Node)) {
      return;
    }

    if (this.elementRef.nativeElement.contains(event.target)) {
      event.stopImmediatePropagation();
    }
  }

  public close(closeIcon: MatIcon): void {
    if (!this.closeIcon) {
      this.closeIcon = closeIcon;
    }
    if (this.infoMenu) {
      return;
    }
    this.closeDialog.emit();

    this.dialogRef?.close();
    this.bottomSheetRef?.dismiss();
  }
}
